import React from 'react';
import PropTypes from 'prop-types';

const JqueryFill = ({ color = 'currentColor', size = '24', ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      {...otherProps}
      className="ai ai-JqueryFill"
    >
      <g clipPath="url(#clip0_950_626)">
        <path d="M13.301 5.601c-.628-1.528-.54-3.247.367-4.63L14.346 0c-1.254 2.35-.06 5.236 1.86 6.749a6.343 6.343 0 0 0 1.698.95c.164.06.35.124.52.167 2.146.54 3.895.167 4.936-1.389-.032.048-.073.124-.125.22-.464.86-1.822 3.377-5.76 2.587-.075-.015-.147-.04-.219-.063l-.064-.022-.046-.015a2.119 2.119 0 0 0-.064-.021 5.8 5.8 0 0 1-.174-.063c-1.585-.604-2.944-1.876-3.607-3.499z"></path>
        <path d="M8.12 2.966c-1.537 2.295-1.453 5.368-.254 7.796a10.242 10.242 0 0 0 .762 1.301c.209.313.439.66.708.902.111.127.227.25.345.371l.068.07.023.023a10.22 10.22 0 0 0 .367.353l.001.001.002.002a10.623 10.623 0 0 0 .465.397l.04.032c.14.112.283.222.43.327l.006.004.007.005c.047.034.095.067.143.099l.053.036a3.109 3.109 0 0 1 .093.064c.105.07.211.137.319.203l.03.017.015.01a11.466 11.466 0 0 0 .351.201l.031.016.04.022c.052.028.104.057.158.084l.03.015a9.941 9.941 0 0 0 .47.224l.032.013a10.315 10.315 0 0 0 .451.186l.03.011c.103.04.206.076.31.112l.052.018.088.029c.039.012.078.026.116.039.109.038.218.075.332.095C22.168 17.408 24 11.068 24 11.068c-1.651 2.468-4.849 3.646-8.261 2.726a9.54 9.54 0 0 1-.45-.135c-.04-.012-.08-.026-.12-.04l-.015-.005a8.82 8.82 0 0 1-.314-.112l-.003-.002a10.08 10.08 0 0 1-.512-.21l-.056-.025a9.272 9.272 0 0 1-.58-.287l-.042-.022-.12-.064a9.64 9.64 0 0 1-.325-.19 10.318 10.318 0 0 1-.62-.41 10.976 10.976 0 0 1-.429-.326l-.035-.029a6.304 6.304 0 0 1-.061-.05c-1.494-1.224-2.678-2.897-3.24-4.793-.59-1.968-.463-4.176.559-5.968L8.12 2.966z"></path>
        <path d="M1.524 5.637C-.6 8.807-.336 12.932 1.287 16.3c.03.065.063.129.095.192l.024.048.03.059a2.987 2.987 0 0 0 .08.155l.013.025.07.13.015.027c.05.092.102.184.155.276l.017.03a9.464 9.464 0 0 0 .251.413l.018.03.059.093c.084.13.17.261.26.39v.001a.085.085 0 0 0 .007.009l.024.033.02.028c.078.11.157.22.238.328l.09.118a18.083 18.083 0 0 0 .608.746l.006.007.005.005.007.008a15.627 15.627 0 0 0 .658.697l.03.03.071.07a12.947 12.947 0 0 0 .346.326l.033.03.026.022a18.342 18.342 0 0 0 .38.33l.056.047c.085.07.171.14.258.208l.137.109c.096.073.192.145.289.215l.016.012.09.066.028.02c.088.064.177.124.267.184l.014.01a5.661 5.661 0 0 1 .12.083 15.577 15.577 0 0 0 .507.319l.054.032a14.516 14.516 0 0 0 .459.26l.053.029.059.032c.059.032.117.064.177.095l.025.012.03.015.034.017c.02.01.041.02.062.032.124.061.248.121.374.18l.047.021.032.015a13.238 13.238 0 0 0 .953.39l.01.003.042.016a13.91 13.91 0 0 0 .69.228c.116.039.233.077.353.1 10.277 1.946 13.262-6.41 13.262-6.41-2.507 3.39-6.957 4.285-11.174 3.289a5.368 5.368 0 0 1-.47-.137l-.118-.037c-.152-.048-.303-.1-.453-.153l-.062-.023c-.135-.05-.267-.1-.4-.154l-.112-.046c-.145-.06-.289-.121-.431-.186l-.046-.02a17.296 17.296 0 0 1-.404-.194l-.041-.022a5.56 5.56 0 0 1-.064-.031l-.214-.113-.068-.036a6.057 6.057 0 0 0-.067-.036l-.12-.066c-.114-.063-.226-.13-.338-.197l-.043-.025-.07-.04a16.203 16.203 0 0 1-.516-.332l-.043-.03a12.657 12.657 0 0 1-1.225-.929 15.269 15.269 0 0 1-.352-.307l-.016-.015a16.412 16.412 0 0 1-.448-.428 11.175 11.175 0 0 1-.25-.255l-.034-.034a13.54 13.54 0 0 1-.38-.414l-.006-.006-.002-.003c-.106-.12-.21-.244-.312-.368l-.082-.101a13.975 13.975 0 0 1-.307-.395c-.088-.12-.175-.238-.26-.359-2.34-3.314-3.181-7.886-1.31-11.64L1.524 5.637z"></path>
      </g>
      <defs>
        <clipPath id="clip0_950_626">
          <rect width="24" height="24"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

JqueryFill.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default JqueryFill;
